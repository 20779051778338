<mat-dialog-content>
	<div class="header">
		<button
			class="minimize-dialog"
			size="small"
			fill="clear"
			title="Minimize call dialog"
			type="button"
			(click)="onHideClick()"
		>
			<ion-icon name="chevron-back-outline" />
			Back to chat
		</button>
		<wndrc-devices-configuration
			(audioInputChange)="onAudioInputSelector($event)"
			(audioOutputChange)="onAudioOutputSelector($event)"
			(videoInputChange)="onVideoInputSelector($event)"
			[isVideoConfigurable]="isVideoCall && platformService.isDesktop"
			[selectedAudioInputId]="activeAudioInputId()"
			[selectedVideoInputId]="activeVideoInputId()"
			[selectedAudioOutputId]="activeAudioOutputId()"
		/>
	</div>
	<wndrc-consultation-progress
		class="session-progress"
		[session]="data.session"
		[timeLeft]="consultationTimer$ | async"
	/>
	@if (isReconnecting()) {
		<div class="reconnect-notification">
			<span>Connection lost. Reconnecting...</span>
		</div>
	}
	<div class="videos">
		<div #subscriber>
			@if (!isParticipantConnected()) {
				<div class="participant-placeholder">
					<wndrc-avatar
						class="pulse-animation"
						[avatarUrl]="''"
						size="m"
					/>
					<span class="subtitle-1">Waiting for {{ data.participant | wndrcFullName }}</span>
				</div>
			}
			@if (isParticipantAudioBlocked()) {
				<div class="participant-mic-off">
					<ion-icon
						icon="mic-off-outline"
						class="participant-mic-off__icon"
					/>
				</div>
			}
		</div>
		<div
			#publisher
			[class.publisher_rear]="isRearCameraUsed()"
		>
			@if (isAudioMuted) {
				<div class="participant-mic-off">
					<ion-icon
						icon="mic-off-outline"
						class="participant-mic-off__icon"
					/>
				</div>
			}
		</div>
	</div>
	<div class="actions">
		<div class="button-container">
			<button
				wndrcIconButton
				color="warn"
				[size]="'medium'"
				type="button"
				(click)="onCallEndClick()"
				class="finish-call-btn"
			>
				<ion-icon icon="call" />
			</button>
			End Call
		</div>
		@if (isVideoCall && data.uiConfiguration.cycleVideoButton) {
			<div class="button-container">
				<button
					wndrcIconButton
					color="primary"
					[size]="'medium'"
					type="button"
					(click)="cycleVideo()"
					class="finish-call-btn"
				>
					<ion-icon icon="camera-reverse-outline" />
				</button>
				Change camera
			</div>
		}
		<div class="button-container">
			<button
				wndrcIconButton
				type="button"
				[size]="'medium'"
				color="primary"
				(click)="toggleAudio()"
			>
				<ion-icon [icon]="!isAudioMuted ? 'mic-outline' : 'mic-off-outline'" />
			</button>
			{{ !isAudioMuted ? "Mute" : "Unmute" }}
		</div>
		@if (isVideoCall) {
			<div class="button-container">
				<button
					wndrcIconButton
					type="button"
					[size]="'medium'"
					color="primary"
					(click)="toggleVideo()"
				>
					<ion-icon [icon]="!isVideoOff ? 'videocam-outline' : 'videocam-off-outline'" />
				</button>
				{{ !isVideoOff ? "Stop Video" : "Start Video" }}
			</div>
		}
	</div>
</mat-dialog-content>
