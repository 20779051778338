import { interval, map, Observable, startWith } from 'rxjs';

import { MSConverter } from '../time-utils';

/**
 * Creates timer for specified duration.
 * @param startDate Start date.
 * @param durationInMin Duration of timer in minutes.
 * @returns Observable emitting amount of milliseconds of time left.
 */
export function createTimerForDuration(startDate: Date, durationInMin: number): Observable<number> {
	const startDateMs = startDate.getTime();
	const duration = durationInMin * MSConverter.Minute;

	/*
		Since our timer starts working not in the whole minute (e.g 00:30:25)
		we want to update value more then 1 time per minute.
	*/
	return interval(MSConverter.Second).pipe(
		startWith(null),
		map(() => duration - (Date.now() - startDateMs)),
		map(timeLeftMs => timeLeftMs > 0 ? timeLeftMs : 0),
	);
}
