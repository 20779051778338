import { Pipe, PipeTransform } from '@angular/core';
import { TimeUtils } from '@wndr/common/core/utils/time-utils';

/** Convert milliseconds to string of HH:MM:SS format. */
@Pipe({
	name: 'wndrcTimeString',
	standalone: true,
})
export class TimeStringPipe implements PipeTransform {

	/** @inheritdoc */
	public transform(minutes: number): string {
		return TimeUtils.formatMillisecondsToTimeString(minutes);
	}
}
