import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Session } from '@wndr/common/core/models/session';
import { SessionStatus } from '@wndr/common/core/models/session-status';
import { SessionType } from '@wndr/common/core/models/session-type';
import { MSConverter } from '@wndr/common/core/utils/time-utils';
import { TimeStringPipe } from '@wndr/common/shared/pipes/time-string.pipe';

/** Consultation progress component. */
@Component({
	selector: 'wndrc-consultation-progress',
	imports: [TimeStringPipe],
	templateUrl: './consultation-progress.component.html',
	styleUrl: './consultation-progress.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsultationProgressComponent {

	/** Session. */
	public readonly session = input.required<Session | null>();

	/** Time left in ms. */
	public readonly timeLeft = input.required<number | null>();

	/** Available session statuses. */
	protected readonly sessionStatus = SessionStatus.ENUM;

	/** Session type. */
	protected readonly sessionType = SessionType;

	/**
	 * Determine if consultation is completed or it's close to the finish (5 mins).
	 * @param session Session.
	 * @param timeLeftMs Time left.
	 */
	protected isConsultationCompletedOrClose(session: Session, timeLeftMs: number | null): boolean {
		return session.status === this.sessionStatus.completed || (timeLeftMs !== null ? timeLeftMs <= (MSConverter.Minute * 3) : false);
	}
}
