import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject, input, signal } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenuModule } from '@angular/material/menu';
import { IonIcon } from '@ionic/angular/standalone';
import { DevicesService } from '@wndr/common/core/services/devices.service';
import { ButtonDirective } from '@wndr/common/shared/components/buttons/button.directive';
import { IconButtonDirective } from '@wndr/common/shared/components/buttons/icon-button.directive';
import { map, take } from 'rxjs';
import { addIcons } from 'ionicons';
import { settingsOutline, settingsSharp } from 'ionicons/icons';
import { SelectSheetData, SelectSheetComponent } from '@wndr/common/shared/components/select-sheet/select-sheet.component';

/** Fake audio output. */
const DEFAULT_AUDIO_OUTPUT = {
	label: 'Default',
	deviceId: 'DEFAULT_AUDIO_OUTPUT_ID',
};

/** Allows users to change audio and video devices. */
@Component({
	selector: 'wndrc-devices-configuration',
	imports: [
		MatMenuModule,
		ButtonDirective,
		IconButtonDirective,
		IonIcon,
	],
	templateUrl: './devices-configuration.component.html',
	styleUrl: './devices-configuration.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesConfigurationComponent {

	private readonly devicesService = inject(DevicesService);

	private readonly bottomSheet = inject(MatBottomSheet);

	/** Can user configure video output. */
	public readonly isVideoConfigurable = input<boolean>(true);

	/** Can user configure audio input. */
	public readonly isAudioInputConfigurable = input<boolean>(true);

	/** Can user configure audio output. */
	public readonly isAudioOutputConfigurable = input<boolean>(true);

	/** Selected audio input id. */
	public readonly selectedAudioInputId = input<string | null>(null);

	/** Selected video input id. */
	public readonly selectedAudioOutputId = input<string | null>(null);

	/** Selected video input id. */
	public readonly selectedVideoInputId = input<string | null>(null);

	/** Is settings block visible. */
	protected readonly settingsOpened = signal(false);

	/** Emits a value when new audio input selected. */
	@Output()
	public readonly audioInputChange = new EventEmitter<string>();

	/** Emits a value when new audio output selected. */
	@Output()
	public readonly audioOutputChange = new EventEmitter<string | undefined>();

	/** Emits a value when new video input selected. */
	@Output()
	public readonly videoInputChange = new EventEmitter<string>();

	public constructor() {
		addIcons({ settingsOutline, settingsSharp });
	}

	/** Handle "click" on settings button. */
	protected onSettingsToggle(): void {
		this.settingsOpened.update(value => !value);
	}

	/** Handle "click" of a button that changes audio output. */
	protected onAudioOutputSelector(): void {
		this.devicesService.audioOutputDevices$.pipe(
			take(1),

			/*
					If component is displayed but there are no output devices,
					e.g. it's safari and it's broken https://github.com/mdn/browser-compat-data/issues/23148
					we show default output that does nothing when select.
				*/
			map(devices => devices.length !== 0 ? devices : [DEFAULT_AUDIO_OUTPUT]),
		)
			.subscribe(devices => {
				const selectedId = devices.length === 1 && devices[0].deviceId === DEFAULT_AUDIO_OUTPUT.deviceId ?
					DEFAULT_AUDIO_OUTPUT.deviceId :
					this.selectedAudioOutputId();

				this.bottomSheet.open<SelectSheetComponent, SelectSheetData>(SelectSheetComponent, {
					data: {
						actions: this.mapDevicesToActions(devices),
						selectedId,
						heading: 'Audio output',
						onSelect: action => this.audioOutputChange.next(
							action.id === DEFAULT_AUDIO_OUTPUT.deviceId ?
								undefined :
								action.id,
						),
					},
				});
			});
	}

	/** Handle "click" of a button that changes audio input. */
	protected onAudioInputSelector(): void {
		this.devicesService.audioInputDevices$.pipe(
			take(1),
		).subscribe(devices => this.bottomSheet.open<SelectSheetComponent, SelectSheetData>(SelectSheetComponent, {
			data: {
				actions: this.mapDevicesToActions(devices),
				selectedId: this.selectedAudioInputId(),
				heading: 'Audio input',
				onSelect: action => this.audioInputChange.next(action.id),
			},
		}));
	}

	/** Handle "click" on video selector. */
	protected onVideoInputSelector(): void {
		this.devicesService.videoInputDevices$.pipe(
			take(1),
		).subscribe(devices => {
			this.bottomSheet.open<SelectSheetComponent, SelectSheetData>(SelectSheetComponent, {
				data: {
					actions: this.mapDevicesToActions(devices),
					selectedId: this.selectedVideoInputId(),
					heading: 'Video input',
					onSelect: action => {
						this.videoInputChange.next(action.id);
					},
				},
			});
		});
	}

	private mapDevicesToActions(devices: { deviceId: string; label: string; }[]): SelectSheetData['actions'] {
		return devices.map(device => ({
			id: device.deviceId,
			label: device.label !== '' ? device.label : device.deviceId,
		}));
	}
}
