@if (session(); as session) {
	@let timeLeftMs = timeLeft();

	@if (session.status === sessionStatus.completed || session.status === sessionStatus.in_progress) {
		<div
			class="progress"
			[class.progress_completed]="isConsultationCompletedOrClose(session, timeLeftMs)"
			[class.progress_in-progress]="session.status === sessionStatus.in_progress"
		>
			<p class="progress__body">
				@if (session.status === sessionStatus.completed) {
					{{ sessionType.toReadable(session.sessionType) }} Ended
				} @else if (session.status === sessionStatus.in_progress) {
					{{ sessionType.toReadable(session.sessionType) }} in Progress
				}
			</p>
			<p class="progress__body">
				@if (timeLeftMs !== null) {
					{{ timeLeftMs | wndrcTimeString }}
				} @else {
					{{ session.durationInMin | wndrcTimeString }}
				}
			</p>
		</div>
	}
}
